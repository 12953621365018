import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit';

interface STATE {
  id: number;
  stateName: string;
}

interface DIVISION {
  name: string;
  shortName: string;
  id: number;
}

interface STATE_DIVISION_PAYLOAD {
  stateData: STATE[];
  divisionData: DIVISION[];
}

interface HEADQUARTER {
  hqNames: string;
  hqId: number;
}
interface State {
  stateDivisionLoader: boolean;
  allStates: STATE[];
  allDivisions: DIVISION[];
  headquaterLoader: boolean;
  allHeadquaters: HEADQUARTER[];
  selectedState: STATE[];
  selectedDivision: DIVISION[];
  selectedHeadquater: HEADQUARTER[];
  pageNumber: number;
}

interface HEADQUARTER_PAYLOAD {
  stateId: number[];
  divisionId: number[];
}

export const fetchStateAndDivisionCreator = createAction(
  'FETCH_STATES_DIVISIONS',
);
export const fetchStateAndDivisionTypename =
  fetchStateAndDivisionCreator().type;

export const fetchItemAndSampleCreator = createAction<any>('ITEM_AND_SAMPLE');
export const fetchItemAndSampleTypeName = fetchItemAndSampleCreator({}).type;

export const fetchHeadquaterCreator =
  createAction<HEADQUARTER_PAYLOAD>('FETCH_HEADQUATERS');
export type FetchHeadquaterType = ReturnType<typeof fetchHeadquaterCreator>;
export const fetchHeadquaterTypename: string = fetchHeadquaterCreator.type;

export const fetchPendencyReportCreator = createAction<any>(
  'FETCH_PENDENCY_REPORT',
);
export const fetchPendencyReportTypeName = fetchPendencyReportCreator({}).type;

export const fetchUserListDataCreator = createAction<any>('FETCH_USER_LIST');
export const fetchUserListDataTypeName = fetchUserListDataCreator({}).type;

export const fetchDivisionStateDataCreator = createAction<any>(
  'FETCH_DIVISION_STATE_DATA',
);
export const fetchDivisionsStateDataTypeName = fetchDivisionStateDataCreator(
  {},
).type;
export const fetchItemSampleAutoSuggestCreator = createAction<any>(
  'FETCH_ITEM_SAMPLE_AUTO_SUGGEST',
);
export const fetchItemSampleAutoSuggestTypeName =
  fetchItemSampleAutoSuggestCreator({}).type;

export const fetchSubordinateCreator = createAction<any>(
  'FETCH_PENDENCY_SUBORDINATE',
);
export const fetchSubordinateTypeName = fetchSubordinateCreator({}).type;
export const fetchPendencyExpectedUtilizationDateCreator = createAction<any>(
  'FETCH_PENDENCY_EXPECTED_UTILIZATION_DATE',
);
export const fetchPendencyExpectedUtilizationDateTypeName =
  fetchPendencyExpectedUtilizationDateCreator({}).type;
const inventoryModulePRHandler = createSlice({
  name: 'INVENTORY_MODULE_PENDENCY_REPORT',
  initialState: {
    stateDivisionLoader: false,
    allStates: [],
    allDivisions: [],
    headquaterLoader: false,
    allHeadquaters: [],
    selectedState: [],
    selectedDivision: [],
    selectedHeadquater: [],
    selectedSubordinate: [],
    selectedStaff: '',
    pageNumber: 1,
    pendencyReportData: {},
    divisionStateData: [],
    itemSampleAutoSuggest: [],
    loading: false,
    adminData: [],
    subordinatesDetailsData: {},
    userListData: [],
    activeUsers: true,
    itemAndSample: [],
    selectedItemAndSample: [],
    pendencyExpectedUtilizationDate: [],
    pendencyPayloadData: {},
    pendencyReportExcelData: {},
    userData: [],
    searchValue: [],
  } as State,
  reducers: {
    setStatesDivisionsLoader: prevState => ({
      ...prevState,
      stateDivisionLoader: true,
      allStates: [],
      allDivisions: [],
    }),
    setStatesDivisions: (
      prevState,
      action: PayloadAction<STATE_DIVISION_PAYLOAD>,
    ) => {
      const {stateData = [], divisionData = []} = action.payload;
      return {
        ...prevState,
        stateDivisionLoader: false,
        allStates: stateData?.length > 0 ? [...stateData] : [],
        allDivisions: divisionData?.length > 0 ? [...divisionData] : [],
      };
    },
    setHeadQuartersLoader: prevState => ({
      ...prevState,
      headquaterLoader: true,
      allHeadquaters: [],
    }),
    setHeadQuarters: (prevState, action: PayloadAction<HEADQUARTER[]>) => ({
      ...prevState,
      headquaterLoader: false,
      allHeadquaters: action.payload?.length > 0 ? [...action.payload] : [],
    }),
    setSelectedState: (prevState, {payload}) => ({
      ...prevState,
      selectedState: payload,
    }),
    setSelectedDivision: (prevState, {payload}) => ({
      ...prevState,
      selectedDivision: payload,
    }),
    setSelectedHeadquater: (prevState, {payload}) => ({
      ...prevState,
      selectedHeadquater: payload,
    }),
    setPageNumber: (prevState, {payload}) => ({
      ...prevState,
      pageNumber: payload,
    }),
    setPendencyReportData: (prevState, {payload}) => ({
      ...prevState,
      pendencyReportData: payload,
    }),
    setDivisionStateData: (prevState, {payload}) => ({
      ...prevState,
      divisionStateData: payload,
    }),
    setLoading: (prevState, {payload}) => ({
      ...prevState,
      loading: payload,
    }),
    setItemSampleAutoSuggest: (prevState, {payload}) => ({
      ...prevState,
      itemSampleAutoSuggest: payload,
    }),
    setAdminData: (prevState, {payload}) => ({
      ...prevState,
      adminData: payload,
    }),
    setSubordinates: (prevState, {payload}) => ({
      ...prevState,
      subordinatesDetailsData: payload,
    }),
    setSelectedSubordinate: (prevState, {payload}) => ({
      ...prevState,
      selectedSubordinate: payload,
    }),
    setUserListData: (prevState, {payload}) => ({
      ...prevState,
      userListData: payload,
    }),
    setSelectedStaff: (prevState, {payload}) => ({
      ...prevState,
      selectedStaff: payload,
    }),
    setActiveUsers: (prevState, {payload}) => ({
      ...prevState,
      activeUsers: payload,
    }),
    setItemAndSample: (prevState, {payload}) => ({
      ...prevState,
      itemAndSample: payload,
    }),
    setSelectedItemAndSample: (prevState, {payload}) => ({
      ...prevState,
      selectedItemAndSample: payload,
    }),
    setPendencyExpectedUtilizationDate: (prevState, {payload}) => ({
      ...prevState,
      pendencyExpectedUtilizationDate: payload,
    }),
    setPendencyPayloadData: (prevState, {payload}) => {
      return {
        ...prevState,
        pendencyPayloadData: payload,
      };
    },
    setPendencyReportExcelData: (prevState, {payload}) => ({
      ...prevState,
      pendencyReportExcelData: payload,
    }),
    setUserData: (prevState, {payload}) => {
      return {
        ...prevState,
        userData: payload,
      };
    },
    setSearchValue: (prevState, {payload}) => {
      return {
        ...prevState,
        searchValue: payload,
      };
    },
  },
});

export const inventoryModulePRActions = inventoryModulePRHandler.actions;
export const inventoryModulePRReducer = inventoryModulePRHandler.reducer;
